import axios from "axios";
import React, { useEffect, useState } from "react";
import { Col, Row, Container } from "react-bootstrap";
import { Search } from "react-bootstrap-icons";
import "./media.scss";
import Layout from "../layout/Layout";
import LoadingSpinner from "../loader/LoadingSpinner";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import { Helmet } from "react-helmet";

function Images() {
  const [category, setCategory] = useState([]);
  const [imagesAll, setImagesAll] = useState([]);
  const [activeMenu, setActiveMenu] = useState("All");
  const [isLoading, setIsLoading] = useState(false);
  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [lightboxIndex, setLightboxIndex] = useState(0);

  useEffect(() => {
    setIsLoading(true);
    getImages("All"); // Fetch all images initially
    axios.get("https://admin.bahwanauto.com/api/v1/categories_list").then((res) => {
      setCategory(res.data);
      setIsLoading(false);
    });
  }, []);

  const getImages = (cat) => {
    setIsLoading(true);
    axios
      .get(`https://admin.bahwanauto.com/api/v1/media_by_images/${cat}`)
      .then((res) => {
        setImagesAll(res.data);
        setIsLoading(false);
      });
  };

  // Lightbox functionality
  const handleShow = (index) => {
    setLightboxOpen(true);
    setLightboxIndex(index);
  };

  const handleClose = () => {
    setLightboxOpen(false);
  };

  return (
    <Layout LoadingSpinner={isLoading}>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <Container fluid className="mt-5 pt-5 gallery-container">
          <Helmet>
            {/* Helmet content remains the same */}
          </Helmet>
          <Row className="Feaild">
            <Col lg={8}>
              <Row>
                <Col lg={12} md={12} xs={12} sm={12} className="text-center">
                  <span
                    className={activeMenu === "All" ? "showall Active text-white" : "showall"}
                    onClick={() => {
                      getImages("All");
                      setActiveMenu("All");
                    }}
                    style={{ color: 'darkblue' }}
                  >
                    Show All
                  </span>
                  {category &&
                    category.map((element, index) => (
                      <button
                        style={{ color: 'darkblue' }}
                        className="btn"
                        key={index}
                        onClick={() => {
                          getImages(element.name); // Pass category name to fetch images
                          setActiveMenu(element.name); // Set activeMenu to the category's name
                        }}
                      >
                        <span
                          className={activeMenu === element.name ? "titles Active" : "titles"}
                        >
                          {element.name}
                        </span>
                      </button>
                    ))}
                </Col>
              </Row>
            </Col>
          </Row>

          <div className="container">
            <Row className="images-row mb-5">
              {imagesAll && imagesAll.length > 0 ? (
                imagesAll.map((finalImage, index) => (
                  <Col lg={4} key={index} className="image-gallery">
                    <figure className="hover-img" onClick={() => handleShow(index)}>
                      <img
                        src={finalImage.image}
                        className="img-fluid"
                        alt=""
                      />
                      <figcaption>
                        <Search color="white" />
                      </figcaption>
                    </figure>
                  </Col>
                ))
              ) : (
                <div className="mx-auto padding-top-80">
                  <span className="text-center">
                    No images found in this category
                  </span>
                </div>
              )}
            </Row>
          </div>

          {lightboxOpen && (
            <Lightbox
              mainSrc={imagesAll[lightboxIndex].image}
              onCloseRequest={handleClose}
              nextSrc={imagesAll[(lightboxIndex + 1) % imagesAll.length].image}
              prevSrc={imagesAll[(lightboxIndex + imagesAll.length - 1) % imagesAll.length].image}
              onMovePrevRequest={() =>
                setLightboxIndex((lightboxIndex + imagesAll.length - 1) % imagesAll.length)
              }
              onMoveNextRequest={() =>
                setLightboxIndex((lightboxIndex + 1) % imagesAll.length)
              }
            />
          )}
        </Container>
      )}
    </Layout>
  );
}

export default Images;
