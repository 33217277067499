// src/components/NotFound.js
import React from 'react';
import { Link } from 'react-router-dom';
import './NotFound.css'; // Optional CSS file for styling
import Layout from '../layout/Layout';
const NotFound = () => {
  return (
    <Layout>
    <div className="not-found">
      <h1 className="mb-4">404 - Page Not Found</h1>
      <p>Sorry, the page you are looking for does not exist.</p>
      <Link to="/"><button className='btn btn-primary'>Go to Home</button></Link>
    </div>
    </Layout>
  );
};

export default NotFound;
