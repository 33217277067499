import axios from "axios";
import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

const Navigation = () => {
  const location = useLocation();
  const [scrolled, setScrolled] = useState(false);
  const segments = location.pathname.split('/');
  const HomeClass = location.pathname === '/' ? "active" : "";
  const aboutClass = location.pathname.match(/^\/about/) ? "active" : "";
  const accessClass = location.pathname.match(/^\/accessories/) ? "active" : "";
  const careerClass = location.pathname.match(/^\/career/) ? "active" : "";
  const contactClass = location.pathname.match(/^\/contact/) ? "active" : "";
  const galleryClass = location.pathname.match(/^\/images/)
    ? "active"
    : "";
  const brandsClass = location.pathname.match(/^\/our-brands/) ? "active" : "";
  const ourBusinessClass = segments[1] === 'page' ? "active" : "";
  const [brands, setBrands] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [automotovie, setAutomotovie] = useState([]);
  useEffect(() => {
    axios.get("https://admin.bahwanauto.com/api/v1/brands").then((response) => {
      setBrands(response.data);
    });

    axios
      .get("https://admin.bahwanauto.com/api/v1/dynamic_pages")
      .then((response) => {
        setCompanies(response.data);
      });

    axios
      .get("https://admin.bahwanauto.com/api/v1/automotives_list")
      .then((response) => {
        setAutomotovie(response.data);
      });


    const handleScroll = () => {
      const scrollTop =
        window.pageYOffset || document.documentElement.scrollTop;
      setScrolled(scrollTop > 0);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div>
      <div className="body-overlay" id="body-overlay"></div>

      <header>
        {/* <div className="topbar-area style-02">
            <div className="container">
              <div className="row">
                <div className="topbar-inner">
                  <div className="left-content"></div>
                  <div className="right-content">
                    <div className="social-icon">
                      <ul>
                        <li>
                          <a className="icon" href="https://www.facebook.com/bahwanauto" target="_blank">
                            <i className="fab fa-facebook-square"></i>
                          </a>
                        </li>
                        <li> 
                          <Link className="icon" href="#" target="_blank">
                            <i className="fa fa-twitter"></i>
                          </Link>
                        </li>
                       
                        <li>
                          <a className="icon" href="https://www.instagram.com/bahrainthismonth/p/C6Tq5voIb5y/?img_index=1" target="_blank">
                            <i className="fab fa-instagram"></i>
                          </a>
                        </li>
                      <li>
                          <Link className="icon" href="#" target="_blank">
                            <i className="fab fa-linkedin-in"></i>
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
        <div
          className={`${scrolled ? "scrolled sticky-nav-container" : "sticky-nav-container"
            }`}
        >
          <div className="container">
            <div className="row">
              <div className="header-bottom-area">
                <div className="logo-area">
                  <Link reloadDocument={true} to="/">
                    <img
                      src="/logo192.png"
                      alt="Logo"

                    />
                  </Link>
                </div>


                <nav className="navbar navbar-area navbar-expand-lg style-02 ">
                  <div className="container nav-container">
                    <button
                      className="navbar-toggler"
                      type="button"
                      data-toggle="collapse"
                      data-target="#autoshop_main_menu"
                      aria-expanded="false"
                      aria-label="Toggle navigation"
                    >
                      <span className="humberger-menu black">
                        <span className="one"></span>
                        <span className="two"></span>
                        <span className="three"></span>
                      </span>
                    </button>
                    <div
                      className="collapse navbar-collapse"
                      id="autoshop_main_menu"
                    >
                      <ul className="navbar-nav">
                        <li>
                          <Link to="/" className={HomeClass}>
                            <i className="fa fa-home 2x"></i>
                          </Link>
                        </li>
                        <li>
                          <Link to="/about" className={aboutClass}>
                            About Us
                          </Link>
                        </li>
                        <li className="menu-item-has-children">
                          <a href="#">Automotive</a>
                          <ul className="sub-menu">
                            {automotovie.map((automotovie_item, automotovieIndex) => {
                              return (
                                <li key={automotovieIndex}>
                                  <a href={"/automotovie/" + automotovie_item.slug}>
                                    {automotovie_item.title}
                                  </a>
                                </li>
                              );
                            })}
                          </ul>
                        </li>

                        <li className="menu-item-has-children">
                          <a href="#" className={ourBusinessClass}>Our Businesses</a>
                          <ul className="sub-menu">
                            {companies &&
                              companies.map((element, index) => {
                                return (
                                  <li key={index}>
                                    <Link to={"/page/" + element.slug}>
                                      {element.title}
                                    </Link>
                                  </li>
                                );
                              })}
                          </ul>
                        </li>

                        <li>
                          <Link to="/images" className={galleryClass}>
                            Gallery
                          </Link>
                        </li>
                        {/* <li>
                            <Link to="/videos" className={accessClass}>
                            Videos
                            </Link>
                          </li> */}
                        <li>
                          <Link to="/career" className={careerClass}>
                            Career
                          </Link>
                        </li>
                        <li>
                          <Link to="/contact" className={contactClass}>
                            Contact
                          </Link>
                        </li>

                      </ul>
                    </div>
                  </div>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </header>
    </div>
  );
};

export default Navigation;
