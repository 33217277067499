import axios from "axios";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom"


const Footer = () => {
    const [companies, setCompanies] = useState([]);

    const [pages, setPages] = useState();

    useEffect(() => {
        axios
            .get("https://admin.bahwanauto.com/api/v1/pages_list")
            .then((response) => {
                setPages(response.data);
            });


        axios
            .get("https://admin.bahwanauto.com/api/v1/dynamic_pages")
            .then((response) => {
                setCompanies(response.data);
            });


    }, [0]);


    return (
        <>
            <footer className="footer-area style-02">
                <div className="footer-top ">
                    <div className="container">

                        <div className="row padding-top-50 padding-bottom-10">
                            <div className="col-lg-3 col-md-3 col-xs-6 px-lg-3">
                                <div className="footer-widget widget widget_nav_menu">
                                    <h5 className="widget-title">Our Company</h5>
                                    <ul>
                                        <li><Link to="/">Home</Link></li>
                                        <li><Link to="/about">About Us</Link></li>
                                        <li><Link to="/images">Gallery</Link></li>
                                        <li><Link to="/contact">Contact</Link></li>
                                        {pages && pages.map((element, index) => (
                                            <li key={index}>
                                                <Link to={`/static/${element.slug}`}>{element.title_en}</Link>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-6  col-md-6 col-xs-6">
                                <div className="footer-widget widget widget_nav_menu">
                                    <h5 className="widget-title">Our Businesses</h5>
                                    <div className="row">
                                        <div className="col-6">
                                            <ul>
                                                {companies.map((company, index) => {
                                                    if (index == 0 || index == 1 || index == 2 || index == 3 || index == 4) {

                                                        return (
                                                            <li key={index}><Link to={"/page/" + company.slug}>{company.title}</Link></li>
                                                        )
                                                    }
                                                })}
                                            </ul>
                                        </div>
                                        <div className="col-6">
                                            <ul>
                                                {companies.map((company, index) => {
                                                    if (index == 4 || index == 5 || index == 6 || index == 7 || index == 8) {

                                                        return (
                                                            <li key={index}><Link to={"/page/" + company.slug}>{company.title}</Link></li>
                                                        )
                                                    }
                                                })}
                                            </ul>
                                        </div>
                                    </div>


                                </div>
                            </div>
                            <div className="col-lg-3 col-md-3 col-xs-3">
                                <div className="footer-widget widget">
                                    <h5 className="widget-title">Contact us</h5>
                                    <div className="contact-area follow">
                                        <ul>
                                            <li><i className="icon flaticon-pin"></i>Bahwan Auto</li>
                                            <li><i className="icon flaticon-email"></i><a href="mailto:info@bahwanauto.com">info@bahwanauto.com</a></li>
                                            {/* <li><i className="icon flaticon-call-answer"></i><a href="tel:971 00 000 000">Local :  +971 00 000 000</a></li> */}
                                            {/* <li><i className="icon flaticon-call-answer"></i><a href="tel:971 0000 0000">Int'l : +971 0000 0000</a></li> */}
                                        </ul>
                                    </div>
                                </div>
                            </div>


                            {/* <div className="col-lg-3 col-md-3 col-xs-6 px-lg-3">
                                <div className="footer-widget widget widget_nav_menu">
                                    <h5 className="widget-title"><div className="text">FOLLOW US</div></h5>
                                    <div className="footer-social-icon padding-top-10">
                                        <div className="banner__header__follow_us">

                                        <div className="banner__header__icon">
                                                <ul className="social-icons icon-circle icon-rotate list-unstyled list-inline">

                                                    <li><a className="icon" href="https://www.facebook.com/bahwanauto"
                                                        target="_blank"><i className="fab fa-facebook-f"></i></a></li>
                                                   <li><Link className="icon" href="#"
                                                        target="_blank"><i className="fa fa-twitter"></i></Link></li>
                                                    <li><a className="icon" href="https://www.instagram.com/bahrainthismonth/p/C6Tq5voIb5y/?img_index=1"
                                                        target="_blank"><i className="fab fa-instagram"></i></a></li>
                                                   <li><Link className="icon"
                                                        href="#"
                                                        target="_blank"><i className="fab fa-linkedin-in"></i></Link></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>


                                </div>



                            </div> */}
                        </div>
                    </div>
                </div>
                <div className="copyright-area">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="copyright-area-inner">
                                    &copy; Bahwan Auto All rights reserved.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>

            <div className="back-to-top">
                <span className="back-top"> <img src="/assets/img/back-to-top.png" alt="img" /> </span>
            </div>
        </>
    )
}

export default Footer