import logo from './logo.svg';
import './App.scss';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Home from './components/home/Home';
import About from './components/about/About';
import Accessories from './components/accessories/Accessories';
import Contact from './components/contact-us/Contact';
import Genuine_parts from './components/genuine-parts/Genuine_parts';
import Login from './components/login/Login';
import Register from './components/login/Register';
import Dynamic_pages from './components/dynamic-page/Dynamic_pages';
import Brands from './components/brands/Brands';
import Explore from './components/explore/Explore';
import Images from './components/media/Images';
import Videos from './components/media/Video';
import News from './components/news/News';
import SingleNews from './components/news/SingleNews';
import NotFound from './components/NotFound/NotFound';
import Staticpage from './components/static-pages/Staticpage';
import Automotive from './components/dynamic-page/Automotive';
import CareerPage from './components/career/career';
import JobDetails from './components/career/JobDetails';
function App() {
  return (
    <div className="App">


      <Router>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="about" element={<About />} />
          <Route exact path="videos" element={<Videos />} />
          <Route exact path="images" element={<Images />} />
          <Route exact path="news" element={<News />} />
          <Route exact path="contact" element={<Contact />} />
          <Route exact path="/page/:name" element={<Dynamic_pages />} />
          <Route exact path="/brand/:name" element={<Brands />} />
          <Route exact path="/explore/:id" element={<Explore />} />
          <Route exact path="news/:id" element={<SingleNews />} />
          <Route exact path="/automotovie/:name" element={<Automotive />} />
          <Route exact path="/static/:id" element={<Staticpage />} />
          <Route exact path="career" element={<CareerPage />} />
          <Route exact path="job-description/:slug" element={<JobDetails />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
