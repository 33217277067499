import React, {  useEffect, useState } from "react";
import Layout from "../layout/Layout";
import axios from "axios";
import { toast } from "react-toastify";
import "./contact.scss";
import Testing from "./Map";
import { Helmet } from 'react-helmet';
import LoadingSpinner from "../loader/LoadingSpinner";

export default function Contact() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    subject: "",
    message: "",
    is_agree: false,

  });
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [metaData, setMetaData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState('Send Message');
  const [addresses, setAddresses] = useState([]);
  const [brands, setBrands] = useState([]);

  const onChange = (e) => {
    const { name, type, value, checked } = e.target;

    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value, // Use `checked` for checkboxes, `value` for others
    });
  };

  const onChangeBrand = (e) => {
    setSelectedLocations([]);
    const userSelectedLocation = [];
    addresses.map((location) => {
      if (!userSelectedLocation.includes(location)) {
        if (location.brand == e.target.value) {
          userSelectedLocation.push(location);
        }
      }
      setSelectedLocations(userSelectedLocation);
    });

    console.log(selectedLocations);
  };

 
useEffect(() => {
  const fetchData = async () => {
    try {
      const [contactResponse,] = await Promise.all([
        axios.get("https://admin.bahwanauto.com/api/v1/addresses")
      ]);
      setAddresses(contactResponse.data.addresses);
      setBrands(contactResponse.data.brands);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false); 
    }
  }

fetchData()


}, [0]);

  const onsubmitContact = async (e) => {
    setIsSubmitting("Submitting....")
    await axios
      .post(`https://admin.bahwanauto.com/api/v1/get_in_touch`, formData)
      .then((res) => {
        if (res.data.success) {
          toast.success("Your request has been successfully submitted");
          setFormData({
            name: "",
            email: "",
            phone: "",
            subject: "",
            message: "",
            is_agree:"false"
          });
        }
        setIsSubmitting("Send Message")
      })
      .catch((error) => {
        setIsSubmitting("Send Message")
        if (error.response.status == 403 || error.response.status == 401) {
          if (typeof error.response.data.errors === "object") {
            for (var key in error.response.data.errors) {
              toast.error(error.response.data.errors[key].message);
            }
          }
        } else {
          toast.error("please fill all the feilds");
        }
      });
  };



  return (
    <Layout LoadingSpinner={isLoading}>
    {isLoading ? (
      <LoadingSpinner />
    ) : (
      <>
        {metaData && (
              <Helmet>
                <title>{metaData.meta_title}</title>
                <meta name="description" content={metaData.meta_description} />
                <meta name="keywords" content={metaData.meta_keywords} />
                <meta name="robots" content={metaData.meta_robots} />
                <meta name="author" content={metaData.meta_author} />

                <meta property="og:title" content={metaData.og_title} />
                <meta property="og:description" content={metaData.og_description} />
                <meta property="og:image" content={metaData.og_image} />
                <meta property="og:locale" content={metaData.og_local} />
                <meta property="og:type" content={metaData.og_type} />
                <meta property="og:url" content={metaData.og_url} />

                <meta name="twitter:card" content={metaData.twitter_card} />
                <meta name="twitter:title" content={metaData.twitter_title} />
                <meta name="twitter:description" content={metaData.twitter_description} />
                <meta name="twitter:image" content={metaData.twitter_image} />

                <link rel="canonical" href={metaData.canonical} />
            </Helmet>
        )}
        <div className="contact-page padding-top-125 padding-bottom-10 mt-5">
          <div className="container mb-5">
            <div className="row col-md-11 col-sm-12 col-xs-12 mx-auto">
              <div className="col-lg-8 col-sm-12 col-xs-12 mx-auto">
                <h2 className="heading-02 padding-bottom-20 text-center">
                  Get In Touch
                </h2>
                <form>
                  <div className="row">

                    <div className="col-md-6 col-sm-12 col-xs-12">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            value={formData.name}
                            placeholder="Name"
                            onChange={onChange}
                            id="name"
                            name="name"
                          />
                        </div>
                        <div className="form-group">
                          <input
                            type="email"
                            className="form-control"
                            value={formData.email}
                            placeholder="Email"
                            onChange={onChange}
                            id="email"
                            name="email"
                          />
                        </div>
                        <div className="form-group">
                          <input
                            type="tel"
                            className="form-control"
                            value={formData.phone}
                            placeholder="Phone"
                            onChange={onChange}
                            id="phone"
                            name="phone"
                          />
                        </div>
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            value={formData.subject}
                            placeholder="Subject"
                            onChange={onChange}
                            id="subject"
                            name="subject"
                          />
                        </div>
                    </div>

                    <div className="col-md-6 col-sm-12 col-xs-12">
                        <div className="form-group">
                          <textarea
                            className="form-control"
                            cols="30"
                            value={formData.message}
                            onChange={onChange}
                            rows="5"
                            id="message"
                            name="message"
                            placeholder="Message"
                          ></textarea>
                        
                      
                      </div>
                      <div className="form-group">
                          <input
                          type="checkbox"
                          name="is_agree"
                          checked={formData.is_agree} // Bind the checked state to formData.is_agree
                          onChange={onChange} // Use the universal onChange function
                        />
                        &nbsp; I agree to the privacy statement and understand how my personal data will be processed.
                        </div>
                       <div className="main-btn-wrap mt-4">
                          <input
                            className="main-btn black"
                            type="button"
                            onClick={() => onsubmitContact()}
                            value={isSubmitting}
                            disabled={isSubmitting ==="Submitting...." && "disabled" }
                          />
                        </div>
                      </div>
               

                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="google-map-section">
          <div className="row mt-5">
            <div className="col-md-6 col-sm-12 col-xs-12 mx-auto">
              <div className="row">
              <div className="col-md-6 col-sm-12 col-xs-12 mx-auto">
                <div className="form-group">
                  <select
                    className="form-control"
                    name="brand"
                    id="brand"
                    onChange={onChangeBrand}
                  >
                    <option>--SELECT BRANDS--</option>
                    {brands && brands.map((brand, brandIndex) => (
                      <option value={brand} key={brandIndex}>
                        {brand}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              </div>
            </div>
          </div>
          <Testing data={selectedLocations.length>0?selectedLocations:addresses} />
        </div>
      </>
    )}
    </Layout>
);}
